import React from "react"
import Cookies from "js-cookie"
import { Alert, CircularProgress } from "@mui/material"
import { useNavigate } from "react-router-dom"

import api, { login } from "../api"
import Navbar from "../components/Navbar"
import Survey from "../components/Survey"
import { useTranslation } from "react-i18next"
import { useQuery } from "../utils/hooks/useQuery"

interface Props {}

const HomePage: React.FC<Props> = props => {

  const {i18n} = useTranslation()
  
  const params = useQuery()
  const surveyId = params.get("id")
  const language = params.get("lang")
  const [survey, setSurvey] = React.useState<any>(null)
  const [fetchError, setFetchError] = React.useState(false)

  const {t} = useTranslation()

  const navigate = useNavigate()

  React.useEffect(() => {
    if(i18n.language !== language){
      i18n.changeLanguage(language ?? "tr")
    }
  }, [i18n, language])

  React.useEffect(() => {
    const fetchSurvey = async () => {
      let token = Cookies.get("auth_token")

      if (!token) {
        try {
          token = await login()
        } catch (error) {
          setFetchError(true)
        }
      }

      if (!token) return

      Cookies.set("auth_code", token)

      const data = { id: surveyId, authCode: token }

      api
        .post("/v1/api/insurance/get-survey-by-id", { ...data })
        .then(response => {
          if(response.data){
            setSurvey(response.data)
          } else {
            setFetchError(true)
          }
        })
        .catch(error => {
          setFetchError(true)
          console.log(">>>>>>> Error: ", error)
        })
    }

    if (!survey && surveyId) {
      fetchSurvey()
    }
  }, [survey, surveyId])

  const onSuccess = () => {
    navigate(`success?survey=${surveyId}&lang=${i18n.language}`)
  }

  return (
    <div>
      <Navbar />
      <main className="content content--with-smallest-container pb-4">
        {
          !surveyId ? (
            <Alert variant="outlined" severity="warning" color="warning">
              {t("no_survey")}
            </Alert>
          ) : (
            survey ? (
              <Survey survey={survey} onSuccess={onSuccess} />
            ) : fetchError ? (
              <Alert variant="outlined" severity="error" color="error">
                {t("fetch_error")}
              </Alert>
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </div>
            )
          )
        }
      </main>
    </div>
  )
}

export default HomePage
