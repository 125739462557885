import axios from "axios"
import Cookies from "js-cookie"

let api = axios.create({
  baseURL: process.env.REACT_APP_API_URL ?? "https://service.creditwestbank.com:55700",
  headers: {
    Accept: "application/json"
  }
})

api.interceptors.request.use(request => {
  const authToken = Cookies.get("auth_token")

  if (authToken && request.headers) {
    request.headers["Authorization"] = `Bearer ${authToken}`
  }

  return request
})

api.interceptors.response.use(
  response => {
    return response
  },
  function (error) {
    if (error.response?.status === 401) {
      localStorage.removeItem("auth_token")
      Cookies.remove("auth_token")
    }
    return Promise.reject(error)
  }
)

export const login = async () => {
  const response = await api.post("/v1/api/insurance/authenticate", {
    username: process.env.REACT_APP_USERNAME,
    password: process.env.REACT_APP_PWD
  })
  if (response.status === 200) {
    return response.data.code
  } else {
    return null
  }
}

export default api
