import React from "react"
import { Link } from "react-router-dom"
import { AppBar, Toolbar } from "@mui/material"

interface Props { }

const Navbar:React.FC<Props> = () => {

  return (
    <AppBar position="static">
      <Toolbar
        variant="dense"
        style={{
          background: "#fff",
          color: "#000",
          justifyContent: "space-between",
          padding: "7.5px"
        }}
      >
        <Link to="/">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              cursor: "pointer"
            }}
          >
            <img
              src="/images/creditwest-insurance-logo.png"
              alt="Creditwest Insurance Survey"
              style={{ height: "48px" }}
            />
          </div>
        </Link>
      </Toolbar>
    </AppBar>
  )
}

export default Navbar