export const ru = {
  translations: {
    "submit": "Отправлять",
    "name": "Имя",
    "answer": "",
    "answer_placeholder": "Напишите здесь ваш ответ...",
    "home_page": "Главная страница",
    "back_to": "Вернуться к ",
    "our_surveys": "Наши опросы",
    "success_message": "Ваши ответы на опрос успешно получены. Спасибо за участие в нем, позволяющие нам улучшить услуги, которые мы предлагаем нашим клиентам.",
    "submission_error": "При отправке ваших ответов на опрос произошла ошибка. Пожалуйста, обновите страницу и повторите попытку. Если проблема не устранена, не стесняйтесь обращаться к нам.",
    "no_survey": "В настоящее время нет выбранных опросов. Пожалуйста, проверьте, не поврежден ли ваш URL. Если эта проблема не устранена, свяжитесь с нами.",
    "fetch_error": "При получении опроса произошла ошибка. Пожалуйста, обновите страницу и повторите попытку. Если ошибка повторится, пожалуйста, свяжитесь с нами."
  }
}
