import "./assets/styles/main.scss"

import { BrowserRouter, Routes, Route } from "react-router-dom"
import HomePage from "./pages/Home"
import SuccessPage from "./pages/SuccessPage"

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/success" element={<SuccessPage />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
