import React from "react"
import { Alert } from "@mui/material"
import Navbar from "../components/Navbar"
import { useTranslation } from "react-i18next"

interface Props {}

const SuccessPage: React.FC<Props> = props => {

  const {t} = useTranslation()

  return (
    <div>
      <Navbar />
      <main className="content content--with-smallest-container pb-4">
        {
          <Alert variant="outlined" severity="success" color="success">
            {t("success_message")}
          </Alert>
        }
        <div style={{ display: "flex", justifyContent: "center" }} className="mt-3">
          <a href="https://www.creditwestinsurance.com/">{t("home_page")}</a>
        </div>
      </main>
    </div>
  )
}

export default SuccessPage
