export const en = {
  translations: {
    "submit": "Submit",
    "name": "Name",
    "answer": "Answer",
    "answer_placeholder": "Type your answer here...", 
    "home_page": "Home Page",
    "back_to": "Back to",
    "our_surveys": "Our Surveys",
    "success_message": "Your responses to our survey have been successfully received. Thank you for your participation in our survey, which allows us to improve the services we offer to our customers.",
    "submission_error": "An error occurred while sending your responses to the survey. Please refresh the page and try again. If the problem persists, do not hesitate to contact us.",
    "no_survey": "There are currently no polls selected. Please check if your URL is broken. If this problem persists, contact us.",
    "fetch_error": "An error occurred while fetching the survey. Please refresh your page and try again. If the error persists, please feel free to contact us."
  }
}
