import React from "react"
import { IChoice, IQuestion, IQuestionAnswer } from "../types"

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@mui/material"
import { useTranslation } from "react-i18next"

interface Props {
  question: IQuestion
  type: "choice" | "text"
  defaultValue?: IQuestionAnswer
  onChange: (value: any) => void
}

const Question: React.FC<Props> = ({ type, question, onChange }) => {
  const [value, setValue] = React.useState<any>()

  const {t, i18n} = useTranslation()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = (event.target as HTMLInputElement).value

    setValue(val)

    onChange({
      questionId: question.id,
      choiceId: question.isTextAnswer ? null : val,
      answer: question.isTextAnswer ? val : null
    })
  }

  const getLocalizedText = (item: IQuestion | IChoice) => {
    switch (i18n.language) {
      case "tr":
        return item.textTurkish
      case "en":
        return item.textEnglish
      case "ru":
        return item.textRussian
      default:
        return item.textTurkish
    }
  }

  return (
    <div className="mb-3">
      <Typography variant="h6" className="mb-2">
        {getLocalizedText(question)}
      </Typography>
      {type === "text" ? (
        <div>
          <TextField
            rows={3}
            multiline
            fullWidth
            size="small"
            name="answer"
            margin="dense"
            label={t("answer")}
            onChange={handleChange}
            placeholder={t("answer_placeholder")}
          />
        </div>
      ) : (
        <div>
          <FormControl>
            <RadioGroup
              value={value}
              onChange={handleChange}
              name="radio-buttons-group"
              aria-labelledby="demo-radio-buttons-group-label"
            >
              {question.choices.map((item) => {
                return (
                  <FormControlLabel
                    value={item.id}
                    control={<Radio size="small" />}
                    label={getLocalizedText(item)}
                    key={Math.random()}
                    style={{ fontSize: "0.75rem" }}
                  />
                )
              })}
            </RadioGroup>
          </FormControl>
        </div>
      )}
    </div>
  )
}

export default Question
