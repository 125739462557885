export const tr = {
  translations: {
    "submit": "Gönder",
    "name": "Ad Soyad",
    "answer": "Cevap",
    "answer_placeholder": "Cevabınızı buraya yazın...",
    "home_page": "Ana Sayfaya",
    "back_to": "Geri Dön ",
    "our_surveys": "Anketlerimiz",
    "success_message": "Anketimize verdiğiniz yanıtlar başarıyla gönderildi. Siz müşterilerimize sunduğumuz hizmetleri geliştirmemizi sağlayan ankete katılımınız için.",
    "submission_error": "Ankete yanıtlarınız gönderilirken bir hata oluştu. Lütfen sayfayı yenileyin ve tekrar deneyin. Sorun devam ederse, bizimle iletişime geçmekten çekinmeyin.",
    "no_survey": "Şu anda seçili anket yok. Lütfen URL'nizin bozuk olup olmadığını kontrol edin. Bu sorun devam ederse bizimle iletişime geçin.",
    "fetch_error": "Anket getirilirken bir hata oluştu. Lütfen sayfanızı yenileyin ve tekrar deneyin. Hata devam ederse, lütfen bizimle iletişime geçmekten çekinmeyin."
  }
}
