import i18n from "i18next"
import { InitOptions } from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { en, tr, ru } from "./locales"

const options: InitOptions = {
  interpolation: {
    escapeValue: false,
    formatSeparator: ","
  },

  debug: false,
  lng: i18n.options.lng,
  load: "currentOnly",
  resources: {
    tr: tr,
    en: en,
    ru: ru,
  },

  fallbackLng: "tr",

  ns: ["translations"],

  defaultNS: "translations",
  keySeparator: false,

  react: {
    bindI18n: "languageChanged loaded",
    nsMode: "default",
    useSuspense: true
  }
}

i18n.use(LanguageDetector).init(options)

export default i18n
