import React from "react"

import { ToggleButton, ToggleButtonGroup } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"
import { useQuery } from "../utils/hooks/useQuery"

interface Props {}

const LanguagePicker: React.FC<Props> = props => {

  const { i18n } = useTranslation()
  
  const navigate = useNavigate()

  const params = useQuery()
  const surveyId = params.get("id")

  const handleChange = (event: React.MouseEvent<HTMLElement>, newLanguage: string) => {
    i18n.changeLanguage(newLanguage)
    navigate(`/?id=${surveyId}&lang=${newLanguage}`)
  }

  return (

    <div>
      <ToggleButtonGroup
        color="primary"
        value={i18n.language ?? "tr"}
        exclusive
        onChange={handleChange}
        aria-label="Language"
      >
        <ToggleButton value="en">EN</ToggleButton>
        <ToggleButton value="tr">TR</ToggleButton>
        <ToggleButton value="ru">RU</ToggleButton>
      </ToggleButtonGroup>
    </div>
  )
}

export default LanguagePicker
