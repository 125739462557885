import React from "react"
import Cookies from "js-cookie"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import api, { login } from "../api"
import { IQuestionAnswer, ISurvey } from "../types"

import Question from "./Question"
import LanguagePicker from "./LanguagePicker"

import { Alert, Button, CircularProgress, TextField, Typography } from "@mui/material"

interface Props {
  survey: ISurvey | any
  onSuccess?: () => void
}

const Survey: React.FC<Props> = props => {
  const { survey } = props

  const { t, i18n } = useTranslation()
  const [loading, setLoading] = React.useState(false)
  const [submitError, setSubmitError] = React.useState(false)

  const { handleSubmit, getValues, setValue } = useForm<any>({
    defaultValues: {
      answers: {}
    }
  })

  const onChange = (newValue: IQuestionAnswer) => {
    setValue("answers", { ...getValues().answers, [newValue.questionId]: newValue })
  }

  const onSubmit = async (values: any) => {
    setLoading(true)

    let token = Cookies.get("auth_token")

    if (!token) {
      try {
        token = await login()
      } catch (error) {
        setSubmitError(true)
      }
    }

    if (!token) {
      setLoading(false)
      return
    }

    const data = {
      plateNo: "",
      platform: "web",
      name: values.name,
      surveyId: survey.id,
      authCode: token,
      answers: Object.values(values.answers)
    }

    await submitToServer(data)

    setLoading(false)
  }

  const submitToServer = async (data: any) => {
    await api
      .post("/v1/api/insurance/submit-survey", { ...data })
      .then(response => {
        if (props.onSuccess) {
          console.log(">>>>> Response: ", response)
          props.onSuccess()
        }
      })
      .catch(error => {
        console.log("Error submitting: ", error)
        setSubmitError(true)
        setLoading(false)
      })
  }

  const getLocalizedText = (item: ISurvey) => {
    switch (i18n.language) {
      case "tr":
        return item.titleTurkish
      case "en":
        return item.titleEnglish
      case "ru":
        return item.titleRussian
      default:
        return item.titleTurkish
    }
  }

  return (
    <div>
      {submitError && (
        <Alert className="mb-3 mt-2" severity="error">
          {t("submission_error")}
          {/* Ankete yanıtlarınız gönderilirken bir hata oluştu. Lütfen sayfayı yenileyin ve tekrar
          deneyin. Sorun devam ederse, bizimle iletişime geçmekten çekinmeyin. */}
        </Alert>
      )}
      <div className="mb-3" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <LanguagePicker />
      </div>
      <Typography variant="h5" className="mb-3" style={{ textAlign: "center" }}>
        {getLocalizedText(survey)}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        {survey.questions.map((item: any) => {
          return (
            <div key={Math.random()}>
              <Question
                question={item}
                onChange={onChange}
                type={item.isTextAnswer ? "text" : "choice"}
              />
            </div>
          )
        })}
        <TextField
          required
          fullWidth
          name="name"
          size="small"
          margin="dense"
          label={t("name")}
          variant="outlined"
          placeholder={t("name")}
          className="mt-2 mb-4"
          onChange={event => {
            setValue("name", event.target.value)
          }}
        />
        <Button fullWidth color="primary" variant="contained" type="submit">
          {loading ? <CircularProgress size={24} style={{ color: "white" }} /> : (t("submit"))}
        </Button>
      </form>
      <div></div>
    </div>
  )
}

export default Survey
